<template>
  <v-container>
    <v-row>
      <v-btn text color="primary" @click="$router.go(-1)"
        ><v-icon left>mdi-chevron-left</v-icon> tous les clients</v-btn
      >
    </v-row>
    <v-row> form here {{ $route.params }} </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ClientDetails",
};
</script>